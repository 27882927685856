<template>
  <div class="page page__search">
    <esmp-table-wrapper
      class="search-table"
      title="Результаты поиска"
      :is-column-settings-button-showed="false"
      :is-allow-full-page="false"
    >
      <esmp-table
        :columns="columns"
        :rows="results"
        stripe
      >
        <template #cell-actions="{ tr }">
          <div class="search-table__actions">
            <span class="search-table__actions-item" @click="loadFormList(tr.id)">
              <esmp-icon name="doc-edit" />
            </span>
          </div>
        </template>
      </esmp-table>
    </esmp-table-wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Search',
  data() {
    return {
      columns: [
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: 'Услуга',
          key: 'name',
        },
        {
          title: '',
          key: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapState('search', ['results']),
  },
  methods: {
    loadFormList(serviceId) {
      this.$router.push({ name: 'Forms', params: { serviceId } });
    },
  },
};
</script>
